import React, { useState } from 'react';
import Confetti from 'react-confetti';
import DatabaseService from '../Services/DatabaseService';

const EmailSignupPage = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [source, setSource] = useState('LeadTiger Landing Page');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  // Funktion zur Überprüfung, ob die eingegebene E-Mail-Adresse gültig ist
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setError(''); // Reset errors
    setSuccess(false); // Reset success messages

    // E-Mail-Adresse überprüfen
    if (!isValidEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    // Name überprüfen
    if (!name.trim()) {
      setError('Please enter your name.');
      return;
    }

    try {
      // Nutze den DatabaseService, um die E-Mail und den Namen in die Datenbank einzufügen
      const result = await DatabaseService.insertEmailSignup(email, name, source);
      setSuccess(true);
      setEmail('');
      setName('');
    } catch (error) {
      setError('Failed to sign up. Please try again later.');
      console.log('Error details:', error);
      console.error('Error during signup:', error);
    }
  };

  return (
    <div className="relative min-h-screen bg-gradient-to-r from-gray-100 via-blue-50 to-indigo-100 flex flex-col items-center justify-center p-4 overflow-hidden">
      {success && <Confetti />}
      {/* Animierte Hintergrundelemente */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 left-0 w-64 h-64 bg-blue-200 rounded-full opacity-50 animate-pulse"></div>
        <div className="absolute bottom-0 right-0 w-96 h-96 bg-purple-200 rounded-full opacity-50 animate-ping"></div>
        <div className="absolute top-1/3 left-1/4 w-48 h-48 bg-pink-200 rounded-full opacity-50 animate-bounce"></div>
      </div>

      <div className="bg-white shadow-xl rounded-lg overflow-hidden max-w-lg w-full relative z-10">
        <div className="bg-gradient-to-r from-blue-500 to-purple-600 text-white text-center py-6 rounded-t-lg">
          <h1 className="text-4xl font-extrabold">Join the LeadTiger Newsletter</h1>
          <p className="mt-2 text-lg font-light">Get the latest updates and exclusive content delivered to your inbox.</p>
        </div>

        <div className="p-8 space-y-6">
          <div className="space-y-6">
            <h2 className="text-2xl font-semibold text-center text-gray-800">Why Subscribe to LeadTiger?</h2>
            <ul className="list-none space-y-6 text-gray-700">
              <li className="flex items-start space-x-3">
                <span className="text-2xl">🎁</span>
                <span>Benefit from a <strong>newsletter discount</strong> at release</span>
              </li>
              <li className="flex items-start space-x-3">
                <span className="text-2xl">🚀</span>
                <span><strong>Follow the development of LeadTiger</strong> so that you are among the first to use the software in your company</span>
              </li>
              <li className="flex items-start space-x-3">
                <span className="text-2xl">✨</span>
                <span>Be the first to know about our <strong>new features</strong></span>
              </li>
            </ul>
          </div>

          <div className="mt-6">
            {error && <p className="text-red-600 text-center">{error}</p>}
            {success && <p className="text-green-600 text-center">Thank you for joining the LeadTiger community!</p>}
            <form onSubmit={handleSignup} className="space-y-4">
              <input
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
              <button
                type="submit"
                className="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white py-3 rounded-lg hover:from-blue-600 hover:to-purple-700 transition-transform transform hover:scale-105"
              >
                Subscribe Now
              </button>
            </form>

            {/* Rechtliche Hinweise */}
            <div className="mt-6 text-gray-600 text-sm">
              <p>
                By subscribing, you agree to our <a href="/privacy-policy" className="text-blue-500 underline">Privacy Policy</a> and <a href="/terms" className="text-blue-500 underline">Terms of Service</a>. You can unsubscribe at any time by clicking the link in the footer of our emails.
              </p>
            </div>

            {/* Unsubscribe Hinweis */}
            <div className="mt-4 text-center text-gray-500 text-xs">
              <a href="/unsubscribe" className="underline">
                Unsubscribe from the newsletter
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailSignupPage;
