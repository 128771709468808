import React, { useState, useEffect } from 'react';
import { Trash2, Filter, FileText, X } from 'lucide-react';
import { supabase } from '../../auth/supabaseClient';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const LeadManager = () => {
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    facebook: false,
    instagram: false,
    website: false,
  });
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [callModalOpen, setCallModalOpen] = useState(false);
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState(null);
  const [exportMenuOpen, setExportMenuOpen] = useState(false);
  const [fileName, setFileName] = useState('leads');
  const [exportType, setExportType] = useState('csv');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  useEffect(() => {
    fetchLeads();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [leads, searchTerm, filters, sortConfig]);

  const fetchLeads = async () => {
    const { data, error } = await supabase
      .from('places')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching leads from Supabase:', error);
    } else {
      setLeads(data);
      checkAllSSLStatus(data);
      setFilteredLeads(data);
    }
  };

  const checkSSL = async (url) => {
    try {
      const response = await fetch(`/api/check-ssl?url=${encodeURIComponent(url)}`);
      const data = await response.json();
      return data.sslValid;
    } catch (error) {
      console.error('Error during SSL check:', error);
      return false;
    }
  };

  const checkAllSSLStatus = async (leads) => {
    const updatedLeads = await Promise.all(
      leads.map(async (lead) => {
        if (lead.website_url) {
          const isValid = await checkSSL(lead.website_url);
          return { ...lead, ssl_valid: isValid };
        }
        return { ...lead, ssl_valid: null };
      })
    );
    setLeads(updatedLeads);
    setFilteredLeads(updatedLeads);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const toggleFilter = (filterType) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: !prevFilters[filterType],
    }));
  };

  const applyFilters = () => {
    let data = leads;

    if (searchTerm) {
      data = data.filter(
        (lead) =>
          lead.name.toLowerCase().includes(searchTerm) ||
          lead.address.toLowerCase().includes(searchTerm) ||
          lead.city?.toLowerCase().includes(searchTerm) || 
          lead.country?.toLowerCase().includes(searchTerm)
      );
    }

    if (filters.facebook) {
      data = data.filter((lead) => lead.facebook_url);
    }
    if (filters.instagram) {
      data = data.filter((lead) => lead.instagram_url);
    }
    if (filters.website) {
      data = data.filter((lead) => lead.website_url);
    }

    // Sorting logic
    if (sortConfig.key) {
      data = data.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    setFilteredLeads(data);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleLeadAction = async (leadId) => {
    const { error } = await supabase
      .from('places')
      .delete()
      .eq('id', leadId);

    if (error) {
      console.error('Error deleting lead:', error);
    } else {
      fetchLeads();
    }
  };

  const handleRowSelect = (leadId) => {
    setSelectedLeads((prevSelected) =>
      prevSelected.includes(leadId)
        ? prevSelected.filter((id) => id !== leadId)
        : [...prevSelected, leadId]
    );
  };

  const handleSelectAll = () => {
    if (selectedLeads.length === filteredLeads.length) {
      setSelectedLeads([]);
    } else {
      setSelectedLeads(filteredLeads.map((lead) => lead.id));
    }
  };

  const handleCallClick = (phoneNumber) => {
    setCurrentPhoneNumber(phoneNumber);
    setCallModalOpen(true);
  };

  const handleConfirmCall = () => {
    setCallModalOpen(false);
    window.location.href = `tel:${currentPhoneNumber}`;
  };

  const exportToCSV = () => {
    const dataToExport = filteredLeads.filter((lead) =>
      selectedLeads.includes(lead.id)
    );

    const csvData = dataToExport.map(lead => ({
      "Company Name": lead.name,
      "Address": lead.address,
      "City": lead.city,
      "Country": lead.country,
      "Branch": lead.branch,
      "Phone Number": lead.phone_number,
      "Facebook URL": lead.facebook_url || '-',
      "Instagram URL": lead.instagram_url || '-',
      "Website URL": lead.website_url || '-',
      "SSL Valid": lead.ssl_valid ? 'Yes' : 'No', // SSL status
    }));

    const csv = Papa.unparse(csvData, {
      quotes: true,
      header: true,
    });

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `${fileName}.csv`);
  };

  const exportToExcel = () => {
    const dataToExport = filteredLeads.filter((lead) =>
      selectedLeads.includes(lead.id)
    );

    const worksheet = XLSX.utils.json_to_sheet(dataToExport.map(lead => ({
      "Company Name": lead.name,
      "Address": lead.address,
      "City": lead.city,
      "Country": lead.country,
      "Branch": lead.branch,
      "Phone Number": lead.phone_number,
      "Facebook URL": lead.facebook_url || '-',
      "Instagram URL": lead.instagram_url || '-',
      "Website URL": lead.website_url || '-',
      "SSL Valid": lead.ssl_valid ? 'Yes' : 'No', // SSL status
    })));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Leads');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, `${fileName}.xlsx`);
  };

  const exportToJSON = () => {
    const dataToExport = filteredLeads.filter((lead) =>
      selectedLeads.includes(lead.id)
    );

    const json = JSON.stringify(dataToExport, null, 2);
    const blob = new Blob([json], { type: 'application/json;charset=utf-8;' });
    saveAs(blob, `${fileName}.json`);
  };

  const handleExport = () => {
    if (exportType === 'csv') {
      exportToCSV();
    } else if (exportType === 'excel') {
      exportToExcel();
    } else if (exportType === 'json') {
      exportToJSON();
    }
    setExportMenuOpen(false);
  };

  return (
    <div className="container mx-auto px-6 py-8 bg-gray-100">
      <h1 className="text-xl font-semibold mb-4">Lead Manager</h1>

      <div className="mb-4 flex justify-between items-center">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search by name, address, city, or country"
          className="w-full p-2 border border-gray-300 rounded-lg"
        />

        <div className="relative ml-4">
          <button
            onClick={() => setFilterMenuOpen(!filterMenuOpen)}
            className="bg-gray-200 text-gray-800 px-4 py-2 rounded-lg flex items-center"
          >
            <Filter className="mr-2" />
            Filter
          </button>

          {filterMenuOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg z-10">
              <div className="py-2">
                <button
                  onClick={() => toggleFilter('facebook')}
                  className={`block w-full text-left px-4 py-2 text-sm ${
                    filters.facebook ? 'bg-blue-500 text-white' : 'text-gray-800 hover:bg-gray-100'
                  }`}
                >
                  Facebook
                </button>
                <button
                  onClick={() => toggleFilter('instagram')}
                  className={`block w-full text-left px-4 py-2 text-sm ${
                    filters.instagram ? 'bg-blue-500 text-white' : 'text-gray-800 hover:bg-gray-100'
                  }`}
                >
                  Instagram
                </button>
                <button
                  onClick={() => toggleFilter('website')}
                  className={`block w-full text-left px-4 py-2 text-sm ${
                    filters.website ? 'bg-blue-500 text-white' : 'text-gray-800 hover:bg-gray-100'
                  }`}
                >
                  Website
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mb-4 text-gray-600">
        {filteredLeads.length} {filteredLeads.length === 1 ? 'Item' : 'Items'}
      </div>

      <div className="mb-4">
        <button
          onClick={() => setExportMenuOpen(true)}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg"
        >
          Export Selected
        </button>
      </div>

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <table className="min-w-full table-fixed">
          <thead>
            <tr className="bg-gray-50 text-left text-gray-500 text-sm">
              <th className="py-3 px-4 font-normal w-16 text-center">
                <input
                  type="checkbox"
                  className="form-checkbox"
                  onChange={handleSelectAll}
                  checked={selectedLeads.length === filteredLeads.length}
                />
              </th>
              <th
                className="py-3 px-4 font-normal w-1/6 text-center cursor-pointer"
                onClick={() => handleSort('name')}
              >
                Company {sortConfig.key === 'name' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
              </th>
              <th
                className="py-3 px-4 font-normal w-1/6 text-center cursor-pointer"
                onClick={() => handleSort('address')}
              >
                Address {sortConfig.key === 'address' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
              </th>
              <th
                className="py-3 px-4 font-normal w-1/6 text-center cursor-pointer"
                onClick={() => handleSort('city')}
              >
                City {sortConfig.key === 'city' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
              </th>
              <th
                className="py-3 px-4 font-normal w-1/6 text-center cursor-pointer"
                onClick={() => handleSort('country')}
              >
                Country {sortConfig.key === 'country' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
              </th>
              <th
                className="py-3 px-4 font-normal w-1/6 text-center cursor-pointer"
                onClick={() => handleSort('branch')}
              >
                Branch {sortConfig.key === 'branch' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
              </th>
              <th className="py-3 px-4 font-normal w-1/6 text-center">Phone</th>
              <th className="py-3 px-4 font-normal w-1/6 text-center">Facebook</th>
              <th className="py-3 px-4 font-normal w-1/6 text-center">Instagram</th>
              <th className="py-3 px-4 font-normal w-1/6 text-center">Website</th>
              <th className="py-3 px-4 font-normal w-1/6 text-center">SSL Valid</th> {/* SSL status column */}
              <th className="py-3 px-4 w-16 text-center"></th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm">
            {filteredLeads.map((lead) => (
              <tr
                key={lead.id}
                className={`border-t border-gray-100 ${
                  selectedLeads.includes(lead.id) ? 'bg-gray-50' : ''
                }`}
              >
                <td className="py-3 px-4 text-center align-middle">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    onChange={() => handleRowSelect(lead.id)}
                    checked={selectedLeads.includes(lead.id)}
                  />
                </td>
                <td className="py-3 px-4 text-center align-middle">{lead.name}</td>
                <td className="py-3 px-4 text-center align-middle">{lead.address}</td>
                <td className="py-3 px-4 text-center align-middle">{lead.city}</td>
                <td className="py-3 px-4 text-center align-middle">{lead.country}</td>
                <td className="py-3 px-4 text-center align-middle">{lead.branch}</td>
                <td className="py-3 px-4 text-center align-middle">
                  <button
                    onClick={() => handleCallClick(lead.phone_number)}
                    className="text-blue-500 hover:underline"
                  >
                    {lead.phone_number}
                  </button>
                </td>
                <td className="py-3 px-4 text-center align-middle">
                  {lead.facebook_url ? (
                    <a
                      href={lead.facebook_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      Facebook
                    </a>
                  ) : (
                    <span className="relative group cursor-pointer inline-block">
                      <span className="inline-block">-</span>
                      <span className="absolute -top-full left-1/2 transform -translate-x-1/2 mb-2 w-max max-w-xs bg-gray-200 text-gray-700 p-2 rounded shadow-md opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none">
                        Kein Facebook-Link vorhanden
                      </span>
                    </span>
                  )}
                </td>
                <td className="py-3 px-4 text-center align-middle">
                  {lead.instagram_url ? (
                    <a
                      href={lead.instagram_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      Instagram
                    </a>
                  ) : (
                    <span className="relative group cursor-pointer inline-block">
                      <span className="inline-block">-</span>
                      <span className="absolute -top-full left-1/2 transform -translate-x-1/2 mb-2 w-max max-w-xs bg-gray-200 text-gray-700 p-2 rounded shadow-md opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none">
                        Kein Instagram-Link vorhanden
                      </span>
                    </span>
                  )}
                </td>
                <td className="py-3 px-4 text-center align-middle">
                  {lead.website_url ? (
                    <a
                      href={lead.website_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      Website
                    </a>
                  ) : (
                    <span className="relative group cursor-pointer inline-block">
                      <span className="inline-block">-</span>
                      <span className="absolute -top-full left-1/2 transform -translate-x-1/2 mb-2 w-max max-w-xs bg-gray-200 text-gray-700 p-2 rounded shadow-md opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none">
                        Keine Website vorhanden
                      </span>
                    </span>
                  )}
                </td>
                <td className="py-3 px-4 text-center align-middle">
                  {lead.ssl_valid ? 'Yes' : 'No'}
                </td> {/* SSL status */}
                <td className="py-3 px-4 text-center align-middle">
                  <div className="flex justify-center">
                    <button
                      onClick={() => handleLeadAction(lead.id)}
                      className="p-1 rounded-full bg-gray-100 text-gray-600 hover:bg-gray-200"
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Export Menu Sidebar */}
      {exportMenuOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">Export Options</h2>
              <button onClick={() => setExportMenuOpen(false)} className="text-gray-500 hover:text-gray-700">
                <X size={20} />
              </button>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">File Name</label>
              <input
                type="text"
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-lg"
                placeholder="Enter file name"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Export Format</label>
              <select
                value={exportType}
                onChange={(e) => setExportType(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-lg"
              >
                <option value="csv">CSV</option>
                <option value="excel">Excel</option>
                <option value="json">JSON</option>
              </select>
            </div>

            <div className="flex justify-end">
              <button
                onClick={handleExport}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg"
              >
                Export
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Call Confirmation Modal */}
      {callModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-lg font-semibold mb-4">Anruf bestätigen</h2>
            <p>Möchten Sie diese Nummer anrufen: {currentPhoneNumber}?</p>
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => setCallModalOpen(false)}
                className="mr-4 bg-gray-200 text-gray-800 px-4 py-2 rounded-lg"
              >
                Abbrechen
              </button>
              <button
                onClick={handleConfirmCall}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg"
              >
                Anrufen
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeadManager;
