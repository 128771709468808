import { supabase } from '../auth/supabaseClient';

class DatabaseService {
  // Funktion zum Einfügen von Orten in die Datenbank
  async insertPlaces(places) {
    try {
      const mappedPlaces = places.map(place => ({
        name: place.name || '',
        address: place.vicinity || '',
        phone_number: place.formatted_phone_number || '',
        website_url: place.website || '',
        google_maps_url: place.url || '',
        facebook_url: place.social?.facebook || '',
        instagram_url: place.social?.instagram || '',
        city: place.city || '',
        country: place.country || '',
        branch: place.branch || '',
        created_at: new Date().toISOString()
      }));

      console.log('Mapped places to be inserted:', JSON.stringify(mappedPlaces, null, 2));

      const { data, error } = await supabase
        .from('places')
        .insert(mappedPlaces);

      if (error) {
        console.error('Error inserting data into Supabase:', error);
        throw error;
      }

      console.log('Data successfully inserted into Supabase:', data);
      return data;
    } catch (err) {
      console.error('Unexpected error during database insertion:', err);
      throw err;
    }
  }

  // Funktion zum Einfügen von E-Mail-Signups in die Datenbank
  async insertEmailSignup(email, name, source) {
    try {
      // Erstelle das Objekt für den Datenbankeintrag
      const emailSignup = {
        email: email || '',
        name: name || '',
        source: source || '',
        created_at: new Date().toISOString(),
      };

      console.log('Email signup to be inserted:', JSON.stringify(emailSignup, null, 2));

      // Führe die Einfügung in die Tabelle `email_signups` durch
      const { data, error } = await supabase
        .from('email_signups')
        .insert([emailSignup]);

      if (error) {
        console.error('Error inserting email signup into Supabase:', error);
        throw error;
      }

      console.log('Email signup successfully inserted into Supabase:', data);
      return data;
    } catch (err) {
      console.error('Unexpected error during email signup insertion:', err);
      throw err;
    }
  }

  async deleteEmailSignup(email) {
    try {
      const { data, error } = await supabase
        .from('email_signups')
        .delete()
        .eq('email', email);

      if (error) {
        console.error('Error deleting email signup:', error);
        throw error;
      }

      console.log('Delete operation result:', data);
      
      // Check if any rows were affected
      const rowsDeleted = data ? data.length : 0;
      
      if (rowsDeleted > 0) {
        console.log(`Email ${email} successfully deleted. Rows affected: ${rowsDeleted}`);
      } else {
        console.warn(`No entries found for email: ${email}`);
      }

      return rowsDeleted > 0;
    } catch (err) {
      console.error('Unexpected error during email deletion:', err);
      throw err;
    }
  }
}

export default new DatabaseService();