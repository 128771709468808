import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <footer className="bg-gray-800 text-white py-8 transition-colors duration-300">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-between">
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h3 className="text-xl font-bold mb-4">SaaSMaster</h3>
              <p>Ihre All-in-One SaaS-Lösung für maximale Produktivität.</p>
            </div>
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h3 className="text-xl font-bold mb-4">Links</h3>
              <ul>
                {['Features', 'Pricing', 'Testimonials', 'Contact'].map((item) => (
                  <li key={item} className="mb-2">
                    <a href={`#${item.toLowerCase()}`} className="hover:text-blue-400 transition-colors duration-300">{item}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full md:w-1/3">
              <h3 className="text-xl font-bold mb-4">Newsletter</h3>
              <form className="flex">
                <input
                  type="email"
                  placeholder="Ihre E-Mail"
                  className="flex-grow p-2 rounded-l dark:bg-gray-700 transition-colors duration-300"
                />
                <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded-r hover:bg-blue-700 transition duration-300">
                  Abonnieren
                </button>
              </form>
            </div>
          </div>
          <div className="mt-8 text-center">
            <p>&copy; 2024 SaaSMaster. Alle Rechte vorbehalten.</p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
