// Remove the Api import
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Home, FileText, Table, Settings, User, LogOut, MoreHorizontal, CreditCard } from 'lucide-react';

const SideMenu = ({ colorTheme = 'blue' }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const menuItems = [
    { icon: Home, text: 'Dashboard', link: '/app/dashboard' },
    { icon: FileText, text: 'Generate', link: '/app/generate' },
    { icon: Table, text: 'Table', link: '/app/table' },
    { icon: FileText, text: 'Generate with New API', link: '/app/generate-with-new-api' }, // Using FileText as a placeholder
  ];

  return (
    <nav className="w-64 bg-white flex flex-col justify-between h-screen border-r border-gray-200">
      <div>
        <div className="p-6 text-2xl font-bold text-blue-600">LeadMapper</div>
        <ul className="mt-6">
          {menuItems.map((item, index) => (
            <li key={index}>
              <Link 
                to={item.link} 
                className="flex items-center px-6 py-3 text-gray-700 hover:bg-gray-100 hover:text-blue-600 transition-colors duration-200"
              >
                <item.icon className="mr-3" size={18} />
                {item.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="p-6">
        <div className="flex flex-col mb-4">
          <Link 
            to="/app/settings" 
            className="flex items-center text-gray-700 hover:text-blue-600 hover:bg-gray-100 px-6 py-3 rounded-lg transition-colors duration-200"
          >
            <Settings className="mr-3" size={18} />
            Settings
          </Link>
          <Link 
            to="/app/billing" 
            className="flex items-center text-gray-700 hover:text-blue-600 hover:bg-gray-100 px-6 py-3 rounded-lg transition-colors duration-200 mt-2"
          >
            <CreditCard className="mr-3" size={18} />
            Manage Billing
          </Link>
          <div className="flex items-center text-sm mt-4">
            <span className="mr-2 text-gray-600">Credits:</span>
            <div className="w-24 bg-gray-200 rounded-full overflow-hidden">
              <div 
                className="bg-blue-600 text-center text-xs leading-none h-1.5" 
                style={{ width: '50%' }} 
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between relative bg-gray-100 p-3 rounded-lg">
          <div className="flex items-center">
            <User className="mr-2 text-gray-600" size={18} />
            <span className="text-gray-700">User</span>
          </div>
          <div className="relative">
            <MoreHorizontal className="cursor-pointer text-gray-600" onClick={toggleDropdown} size={18} />
            {dropdownOpen && (
              <div
                className="absolute right-0 bottom-10 w-48 bg-white rounded-lg shadow-lg py-1 z-10 border border-gray-200"
                style={{ bottom: '40px', right: '0' }}
              >
                <button
                  className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center"
                >
                  <LogOut className="mr-2" size={16} /> Log out
                </button>
                <button
                  className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center"
                >
                  <Settings className="mr-2" size={16} /> Settings
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default SideMenu;
