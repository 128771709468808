import React from 'react';

class ContactForm extends React.Component {
  render() {
    return (
      <section id="contact" className="py-20 bg-gray-50 dark:bg-gray-800 transition-colors duration-300">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Kontaktieren Sie uns</h2>
          <form className="max-w-lg mx-auto">
            <input
              type="email"
              placeholder="Ihre E-Mail-Adresse"
              className="w-full mb-4 p-2 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 transition-colors duration-300"
            />
            <textarea
              placeholder="Ihre Nachricht"
              rows="4"
              className="w-full mb-4 p-2 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 transition-colors duration-300"
            ></textarea>
            <button type="submit" className="w-full bg-blue-600 text-white py-2 rounded-full hover:bg-blue-700 transition duration-300 transform hover:scale-105">
              Nachricht senden
            </button>
          </form>
        </div>
      </section>
    );
  }
}

export default ContactForm;
