import React from 'react';
import { Check } from 'lucide-react';
import { Link } from 'react-router-dom';

class PricingTier extends React.Component {
  render() {
    const { name, price, features, recommended } = this.props;

    return (
      <div className={`w-full md:w-1/3 max-w-sm bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden transition-transform duration-300 hover:scale-105 ${recommended ? 'border-2 border-blue-500' : ''}`}>
        <div className="p-6">
          {recommended && <span className="bg-blue-500 text-white px-2 py-1 rounded-full text-xs font-bold uppercase mb-2 inline-block">Empfohlen</span>}
          <h3 className="text-2xl font-bold mb-4">{name}</h3>
          <p className="text-4xl font-bold mb-6">{price}<span className="text-sm font-normal">/Monat</span></p>
          <ul className="mb-6">
            {features.map((feature, i) => (
              <li key={i} className="flex items-center mb-2">
                <Check className="mr-2 text-green-500" size={20} />
                <span>{feature}</span>
              </li>
            ))}
          </ul>
          <Link to="/auth" className="block w-full bg-blue-600 text-white py-2 rounded-full hover:bg-blue-700 transition duration-300 text-center">
            Jetzt starten
          </Link>
        </div>
      </div>
    );
  }
}

export default PricingTier;