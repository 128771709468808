import React from 'react';

class Testimonials extends React.Component {
  render() {
    const testimonials = [
      { name: 'Max Mustermann', company: 'TechCorp', text: 'SaaSMaster hat unsere Produktivität um 200% gesteigert!' },
      { name: 'Erika Musterfrau', company: 'InnovateCo', text: 'Die Benutzerfreundlichkeit und der Support sind unübertroffen.' }
    ];

    return (
      <section id="testimonials" className="py-20 bg-white dark:bg-gray-900 transition-colors duration-300">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Was unsere Kunden sagen</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg shadow-md transition-all duration-300 hover:shadow-xl">
                <p className="mb-4 italic">"{testimonial.text}"</p>
                <p className="font-bold">{testimonial.name}</p>
                <p className="text-sm text-gray-600 dark:text-gray-400">{testimonial.company}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default Testimonials;
