import { Route } from 'react-router-dom';

function NotFound() {
  return (
    <Route 
      path="*" 
      element={<div>404 - Seite nicht gefunden</div>} 
    />
  );
}

export default NotFound;