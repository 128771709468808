import React from 'react';
import { Outlet } from 'react-router-dom';
import SideMenu from './SideMenu'; // Stellen Sie sicher, dass der Importpfad korrekt ist

const MainLayout = () => {
  return (
    <div className="flex h-screen bg-gray-100"> {/* Hintergrundfarbe an den Generate-Bereich anpassen */}
      <SideMenu />
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100"> {/* Anpassen der Hintergrundfarbe */}
          <div className="container mx-auto px-6 py-8">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

export default MainLayout;
