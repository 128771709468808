import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from './components/LandingPage/LandingPage';
import AuthPage from './components/auth/AuthPage';
import MainLayout from './components/ui/MainLayout';
import Generate from './components/MainLogic/Generate';
import NotFound from './components/ui/NotFound';
import Settings from './components/ui/Settings';
import TableView from './components/ui/LeadView/TableView';
import Dashboard from './components/ui/Dashboard/Dashboard';
import Pricing from './components/LandingPage/Pricing';
import EmailSignupPage from './components/Email/EmailSignupPage';
import UnsubscribePage from './components/Email/UnsubscribePage';
import GenerateWithNewApi from './components/MainLogic/GenerateWithNewApi';
import PrivacyPolicy from './components/Legal/PrivacyPolicy';
import TermsOfService from './components/Legal/TermsOfService';
import PrivateRoute from './components/PrivateRoute'; // PrivateRoute importieren

const App = () => {
  const [colorTheme, setColorTheme] = useState('light');
  const [viewMode, setViewMode] = useState('grid');

  return (
    <Router>
      <Routes>
        {/* Öffentliche Routen */}
       
        <Route path="/signup" element={<EmailSignupPage />} />
        <Route path="/unsubscribe" element={<UnsubscribePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfService />} />

        {/* Geschützte Routen im MainLayout */}
        <Route path="/app" element={<PrivateRoute element={<MainLayout />} />}>
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="generate" element={<Generate />} />
          <Route path="table" element={<TableView />} />
          <Route path="generate-with-new-api" element={<GenerateWithNewApi />} />
          <Route 
            path="settings" 
            element={
              <Settings
                colorTheme={colorTheme}
                setColorTheme={setColorTheme}
                viewMode={viewMode}
                setViewMode={setViewMode}
              />
            } 
          />
        </Route>

        {/* 404 Not Found Seite */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
