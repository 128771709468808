import React from 'react';
import { createClient } from '@supabase/supabase-js';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { Link } from 'react-router-dom';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const AuthPage = () => (
  <div className="min-h-screen flex flex-col bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-indigo-900">
    <header className="py-4 px-6">
      <Link to="/" className="text-2xl font-bold bg-gradient-to-r from-blue-500 to-purple-600 text-transparent bg-clip-text">
        SaaSMaster
      </Link>
    </header>
    <div className="flex-grow flex items-center justify-center px-4">
      <div className="bg-white dark:bg-gray-800 shadow-2xl rounded-lg p-8 max-w-md w-full transition-all duration-300">
        <h2 className="text-3xl font-bold text-center bg-gradient-to-r from-blue-500 to-purple-600 text-transparent bg-clip-text mb-6">
          Willkommen zurück
        </h2>
        <Auth
          supabaseClient={supabase}
          appearance={{
            theme: ThemeSupa,
            variables: {
              default: {
                colors: {
                  brand: '#4F46E5',
                  brandAccent: '#4338CA',
                },
              },
            },
          }}
          providers={['google']}
        />
        <div className="mt-6 text-center">
          <p className="text-gray-600 dark:text-gray-300">
            Noch kein Konto?{' '}
            <Link to="/auth?signup" className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-200 font-semibold">
              Jetzt registrieren
            </Link>
          </p>
        </div>
      </div>
    </div>
    <footer className="py-4 px-6 text-center text-gray-600 dark:text-gray-300">
      &copy; 2024 SaaSMaster. Alle Rechte vorbehalten.
    </footer>
  </div>
);

export default AuthPage;