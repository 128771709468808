import React from 'react';
import { useNavigate } from 'react-router-dom';

const TermsOfService = () => {
  const navigate = useNavigate();

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Mobiler Zurück-Button */}
      <div className="md:hidden mb-4">
        <button
          onClick={() => navigate(-1)}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none"
        >
          &larr; Back
        </button>
      </div>

      <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
      <p className="mb-4">
        These terms of service ("Terms") apply to your access and use of our website and services. By accessing or using our services, you agree to be bound by these Terms.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4">1. Use of Services</h2>
      <p className="mb-4">
        You may use our services only in compliance with these Terms and all applicable laws. You are responsible for your conduct while using our services.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4">2. User Accounts</h2>
      <p className="mb-4">
        You may need to create an account to use some of our services. You are responsible for maintaining the confidentiality of your account credentials and for any activity that occurs under your account.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4">3. Content</h2>
      <p className="mb-4">
        You are responsible for the content you post on our services. We do not endorse or assume any responsibility for any content posted by users.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4">4. Termination</h2>
      <p className="mb-4">
        We may suspend or terminate your access to our services at any time, without notice, for any reason, including if you violate these Terms.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4">5. Limitation of Liability</h2>
      <p className="mb-4">
        Our services are provided "as is" without any warranties. We are not liable for any damages arising from your use of our services.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4">6. Changes to These Terms</h2>
      <p className="mb-4">
        We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on this page.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4">7. Contact Us</h2>
      <p className="mb-4">
        If you have any questions about these Terms, please contact us at [your email address].
      </p>
    </div>
  );
};

export default TermsOfService;
