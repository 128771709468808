import React from 'react';
import Header from './Header';
import Hero from './Hero';
import VideoSection from './VideoSection';
import Features from './Features';
import Pricing from './Pricing';
import Testimonials from './Testimonials';
import Neighbor from './Customers';
import ContactForm from './ContactForm';
import Footer from './Footer';
import DiscountSlider from './DiscountSlider';
import { PricingTier } from './PricingTier';

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      darkMode: false,
      isDiscountVisible: true,
    };
  }

  componentDidMount() {
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    this.setState({ darkMode: prefersDarkMode });
  }

  toggleDarkMode = () => {
    this.setState(prevState => ({ darkMode: !prevState.darkMode }));
    document.documentElement.classList.toggle('dark');
  };

  render() {
    const { darkMode, isDiscountVisible } = this.state;

    return (
      <div className={`min-h-screen flex flex-col transition-colors duration-300 ${darkMode ? 'dark' : ''}`}>
        <Header darkMode={darkMode} toggleDarkMode={this.toggleDarkMode} />
        <main className="flex-grow dark:bg-gray-900 dark:text-white transition-colors duration-300">
          <Hero />
          <VideoSection />
          <Features />
          <Pricing />
          <Testimonials />
          <Neighbor />
          <ContactForm />
        </main>
        <Footer />
        <DiscountSlider 
          isVisible={isDiscountVisible} 
          setIsVisible={visible => this.setState({ isDiscountVisible: visible })} 
        />
      </div>
    );
  }
}

export default LandingPage;