import React from 'react';
import { CreditCard, Sun, Moon, Grid, List, ChevronRight } from 'lucide-react';

const SettingSection = ({ title, children }) => (
  <div className="mb-8 bg-white rounded-lg shadow-md p-6">
    <h2 className="text-xl font-semibold mb-4 pb-2 border-b">{title}</h2>
    {children}
  </div>
);

const ToggleSwitch = ({ label, isChecked, onChange }) => (
  <label className="flex items-center cursor-pointer">
    <div className="relative">
      <input type="checkbox" className="sr-only" checked={isChecked} onChange={onChange} />
      <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
      <div
        className={`absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition ${
          isChecked ? 'transform translate-x-full bg-blue-600' : ''
        }`}
      ></div>
    </div>
    <div className="ml-3 text-gray-700 font-medium">{label}</div>
  </label>
);

const Settings = ({ colorTheme, setColorTheme, viewMode, setViewMode }) => {
  const isDarkMode = colorTheme === 'dark';

  return (
    <div className="max-w-2xl mx-auto p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-8 text-center">Einstellungen</h1>

      <SettingSection title="Erscheinungsbild">
        <div className="space-y-4">
          <ToggleSwitch
            label={
              <div className="flex items-center">
                {isDarkMode ? <Moon className="mr-2" size={18} /> : <Sun className="mr-2" size={18} />}
                {isDarkMode ? 'Dunkelmodus' : 'Hellmodus'}
              </div>
            }
            isChecked={isDarkMode}
            onChange={() => setColorTheme(isDarkMode ? 'light' : 'dark')}
          />
          <div className="flex items-center justify-between">
            <span className="text-gray-700">Suchergebnis Ansicht</span>
            <select
              value={viewMode}
              onChange={(e) => setViewMode(e.target.value)}
              className="border border-gray-300 rounded px-3 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="grid">Rasteransicht</option>
              <option value="list">Listenansicht</option>
            </select>
          </div>
        </div>
      </SettingSection>

      <SettingSection title="Suchpräferenzen">
        <p className="text-gray-600 italic">Weitere Suchoptionen werden bald verfügbar sein...</p>
      </SettingSection>

      <SettingSection title="Konto & Abrechnung">
        <button className="w-full flex items-center justify-between bg-blue-600 text-white px-4 py-3 rounded hover:bg-blue-700 transition-colors duration-200">
          <div className="flex items-center">
            <CreditCard className="mr-2" size={18} />
            <span>Abonnement verwalten</span>
          </div>
          <ChevronRight size={18} />
        </button>
      </SettingSection>
    </div>
  );
};

export default Settings;
