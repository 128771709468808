import React, { useState } from 'react';
import DatabaseService from '../Services/DatabaseService'; // Ensure the import is correct

const UnsubscribePage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleUnsubscribe = async (e) => {
    e.preventDefault();
    setMessage('');

    // Log the email entered by the user
    console.log(`Attempting to unsubscribe email: ${email}`);

    try {
      // Use the deleteEmailSignup method from DatabaseService
      const wasDeleted = await DatabaseService.deleteEmailSignup(email);

      if (wasDeleted) {
        console.log(`Unsubscription successful for email: ${email}`);
        setMessage('You have successfully unsubscribed from the LeadTiger newsletter.');
        setEmail('');
      } else {
        console.warn(`Email not found in the database: ${email}`);
        setMessage('Email not found. Please check the email address and try again or create a Ticket at Schmalze6@gmail.com.');
      }
    } catch (error) {
      // Error handling
      console.error('Error during unsubscribe process:', error);
      setMessage('There was an error processing your request. Please try again later or create a Ticket at Schmalze6@gmail.com.');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-100 via-blue-50 to-indigo-100 flex flex-col items-center justify-center p-4">
      <div className="bg-white shadow-xl rounded-lg overflow-hidden max-w-lg w-full p-8">
        <h1 className="text-3xl font-bold text-center mb-6">Unsubscribe from LeadTiger Newsletter</h1>
        <p className="text-center text-gray-700 mb-4">We're sorry to see you go. Please enter your email address to unsubscribe.</p>
        
        {message && (
          <div className={`text-center p-3 mb-4 ${message.includes('successfully') ? 'text-green-600' : 'text-red-600'}`}>
            {message}
          </div>
        )}

        <form onSubmit={handleUnsubscribe} className="space-y-4">
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
          <button
            type="submit"
            className="w-full bg-red-500 text-white py-3 rounded-lg hover:bg-red-600 transition-transform transform hover:scale-105"
          >
            Unsubscribe
          </button>
        </form>
      </div>
    </div>
  );
};

export default UnsubscribePage;