import React from 'react';

class Neighbor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentNeighbor: 0,
    };
    this.neighbors = [
      { name: 'TechCorp', logo: '🏢', description: 'Unser Nachbar im Innovationszentrum' },
      { name: 'GreenEnergy', logo: '🌿', description: 'Unser Nachbar im Öko-Park' },
      { name: 'FinTech Solutions', logo: '💼', description: 'Unser Nachbar im Finanzviertel' }
    ];
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState(prevState => ({
        currentNeighbor: (prevState.currentNeighbor + 1) % this.neighbors.length
      }));
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const { currentNeighbor } = this.state;
    const neighbor = this.neighbors[currentNeighbor];

    return (
      <section className="py-16 bg-gray-100 dark:bg-gray-800 transition-colors duration-300">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-8">Unsere Nachbarn</h2>
          <div className="max-w-2xl mx-auto bg-white dark:bg-gray-700 p-8 rounded-lg shadow-lg">
            <div className="text-6xl text-center mb-4">{neighbor.logo}</div>
            <h3 className="text-2xl font-bold text-center mb-2">{neighbor.name}</h3>
            <p className="text-center">{neighbor.description}</p>
          </div>
        </div>
      </section>
    );
  }
}

export default Neighbor;
