import React from 'react';
import { Check, Copy, X } from 'lucide-react';
import { Link } from 'react-router-dom';

class DiscountSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
      timeLeft: 24 * 60 * 60, // 24 hours in seconds
    };
    this.discountCode = "SAASBOOST20";
  }

  componentDidMount() {
    if (this.props.isVisible) {
      this.timer = setInterval(() => {
        this.setState(prevState => ({
          timeLeft: prevState.timeLeft > 0 ? prevState.timeLeft - 1 : 0
        }));
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  formatTime(time) {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  copyToClipboard = () => {
    navigator.clipboard.writeText(this.discountCode).then(() => {
      this.setState({ copied: true });
      setTimeout(() => this.setState({ copied: false }), 2000);
    });
  };

  render() {
    const { isVisible, setIsVisible } = this.props;
    const { copied, timeLeft } = this.state;

    return (
      <div className={`fixed bottom-0 left-0 right-0 bg-gradient-to-r from-blue-600 to-purple-600 text-white py-4 transition-all duration-300 ${isVisible ? 'translate-y-0' : 'translate-y-full'}`}>
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex-1 text-center md:text-left mb-4 md:mb-0">
              <h3 className="text-xl font-bold mb-2">🚀 Boost Your Social Media Presence!</h3>
              <p className="text-sm mb-2">Get 20% off on all our Marketing Packages for the next:</p>
              <p className="text-2xl font-bold">{this.formatTime(timeLeft)}</p>
            </div>
            <div className="flex-1 flex justify-center items-center space-x-4">
              <div className="relative">
                <input
                  type="text"
                  value={this.discountCode}
                  readOnly
                  className="bg-white text-gray-800 px-4 py-2 rounded-l-full font-bold"
                />
                <button
                  onClick={this.copyToClipboard}
                  className="bg-yellow-400 text-gray-800 px-4 py-2 rounded-r-full hover:bg-yellow-300 transition duration-300"
                >
                  {copied ? <Check size={20} /> : <Copy size={20} />}
                </button>
              </div>
              <Link to="/pricing" className="bg-white text-blue-600 px-6 py-2 rounded-full font-bold hover:bg-blue-100 transition duration-300">
                Apply Now
              </Link>
            </div>
            <button
              onClick={() => setIsVisible(false)}
              className="absolute top-2 right-2 text-white hover:text-gray-200 transition duration-300"
            >
              <X size={24} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default DiscountSlider;
