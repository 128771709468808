import React, { useState, useCallback, useRef, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, Circle, Marker } from '@react-google-maps/api';
import { Crosshair, Trash2 } from 'lucide-react';
import SearchResults from '../MainLogic/SearchResults';
import DatabaseService from '../Services/DatabaseService';

const mapContainerStyle = {
  width: '100%',
  height: '400px'
};

function PlacesSearch() {
  const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyClzzFFojcfLDQQdnCN4l2DkRRwt2fyX08';
  
  const [keyword, setKeyword] = useState("restaurant");
  const [radiusKm, setRadiusKm] = useState(6);
  const [amount, setAmount] = useState(10);
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [center, setCenter] = useState({ lat: 52.3796664, lng: 9.7614714 });
  const [useTestData, setUseTestData] = useState(false);
  const [savedUrl, setSavedUrl] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [scrapeSocialMedia, setScrapeSocialMedia] = useState(false);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY,
    libraries: ['places']
  });

  const mapRef = useRef(null);
  const circleRef = useRef(null);
  const autocompleteRef = useRef(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const locationParam = urlParams.get('location');
    if (locationParam) {
      setSearchInput(decodeURIComponent(locationParam));
    }
    
    const savedUrlFromStorage = localStorage.getItem('savedMapUrl');
    if (savedUrlFromStorage) {
      setSavedUrl(savedUrlFromStorage);
      const match = savedUrlFromStorage.match(/@(-?\d+\.\d+),(-?\d+\.\d+),(\d+(\.\d+)?)z/);
      if (match) {
        setCenter({ lat: parseFloat(match[1]), lng: parseFloat(match[2]) });
      }
    }
  }, []);

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const onCircleLoad = useCallback((circle) => {
    circleRef.current = circle;
  }, []);

  const onPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place.geometry) {
      setCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      });
      const newUrl = `${window.location.pathname}?location=${encodeURIComponent(place.name || searchInput)}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
  };

  const onSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        },
        () => {
          setError("Standortermittlung fehlgeschlagen. Bitte überprüfen Sie Ihre Browsereinstellungen.");
        }
      );
    } else {
      setError("Geolocation wird von Ihrem Browser nicht unterstützt.");
    }
  };

  const handleSaveUrl = () => {
    const newUrl = `https://www.google.com/maps/@${center.lat},${center.lng},13z`;
    setSavedUrl(newUrl);
    localStorage.setItem('savedMapUrl', newUrl);
  };

  const handleDeleteUrl = () => {
    setSavedUrl('');
    localStorage.removeItem('savedMapUrl');
  };

  const handlePasteUrl = async () => {
    try {
      const pastedText = await navigator.clipboard.readText();
      if (pastedText.startsWith('https://www.google.com/maps')) {
        setSavedUrl(pastedText);
        localStorage.setItem('savedMapUrl', pastedText);
        const match = pastedText.match(/@(-?\d+\.\d+),(-?\d+\.\d+),(\d+(\.\d+)?)z/);
        if (match) {
          setCenter({ lat: parseFloat(match[1]), lng: parseFloat(match[2]) });
        }
      } else {
        alert('Bitte fügen Sie eine gültige Google Maps URL ein.');
      }
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err);
      alert('Fehler beim Einfügen der URL. Bitte versuchen Sie es erneut.');
    }
  };

  const getPlaceDetails = (placeId, service) => {
    return new Promise((resolve, reject) => {
      const fields = [
        'name', 'vicinity', 'formatted_phone_number', 'website', 'url', 'geometry', 'address_components', 'types'
      ];

      const request = { placeId, fields };

      service.getDetails(request, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          let city = '';
          let country = '';
          let branch = '';

          // Extract city and country from address components
          place.address_components.forEach(component => {
            if (component.types.includes('locality')) {
              city = component.long_name;
            }
            if (component.types.includes('country')) {
              country = component.long_name;
            }
          });

          // Extract branch/industry from types array
          if (place.types && place.types.length > 0) {
            branch = place.types[0]; // Use the first type as a branch
          }

          console.log(`Branch for ${place.name}: ${branch}`); // Log the branch for debugging

          const formattedPlace = {
            name: place.name || '',
            vicinity: place.vicinity || '',
            formatted_phone_number: place.formatted_phone_number || '',
            website: place.website || '',
            url: place.url || '',
            lat: place.geometry?.location?.lat() ?? null,
            lng: place.geometry?.location?.lng() ?? null,
            city: city,
            country: country,
            branch: branch,
          };

          resolve(formattedPlace);
        } else {
          console.error(`Failed to get place details: ${status}`);
          resolve({});
        }
      });
    });
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const service = new window.google.maps.places.PlacesService(mapRef.current);
      const request = {
        location: new window.google.maps.LatLng(center.lat, center.lng),
        radius: radiusKm * 1000,
        keyword: keyword
      };

      service.nearbySearch(request, async (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const detailedResults = await Promise.all(
            results.slice(0, amount).map((place) => getPlaceDetails(place.place_id, service))
          );

          console.log('Detailed Results:', detailedResults); // Log the results before insertion

          setResults(detailedResults);
          await DatabaseService.insertPlaces(detailedResults);
        } else {
          throw new Error(`Google Places API Fehler: ${status}`);
        }
      });
    } catch (err) {
      console.error('Error during search or database insertion:', err);
      setError(`Ein Fehler ist aufgetreten: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-8">
      <div className="w-full max-w-5xl">
        <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-3xl font-bold">Google Maps Search Data</h1>
            <button className="bg-blue-500 text-white px-4 py-2 rounded">How it works</button>
          </div>
          
          {isLoaded ? (
            <div className="mb-4">
              <input
                type="text"
                value={searchInput}
                onChange={onSearchInputChange}
                placeholder="Search location"
                className="w-full p-2 border rounded mb-2"
                ref={(input) => {
                  if (input && !autocompleteRef.current) {
                    autocompleteRef.current = new window.google.maps.places.Autocomplete(input, { types: ['(cities)'] });
                    autocompleteRef.current.addListener('place_changed', onPlaceChanged);
                  }
                }}
              />
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={13}
                onLoad={onMapLoad}
              >
                <Marker position={center} />
                <Circle
                  center={center}
                  radius={radiusKm * 1000}
                  onLoad={onCircleLoad}
                  options={{
                    fillColor: "lightblue",
                    fillOpacity: 0.3,
                    strokeColor: "blue",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                  }}
                />
              </GoogleMap>
            </div>
          ) : <div>Loading...</div>}

          <div className="mt-4 space-y-2">
            <div className="flex space-x-2">
              <input
                type="text"
                value={savedUrl}
                readOnly
                className="flex-grow p-2 border rounded"
                placeholder="Gespeicherte Map URL"
              />
              {savedUrl ? (
                <button
                  onClick={handleDeleteUrl}
                  className="bg-red-500 text-white px-4 py-2 rounded flex items-center"
                  title="URL löschen"
                >
                  <Trash2 size={20} />
                </button>
              ) : (
                <button
                  onClick={handleSaveUrl}
                  className="bg-green-500 text-white px-4 py-2 rounded"
                >
                  URL speichern
                </button>
              )}
            </div>
            <button
              onClick={handlePasteUrl}
              className="bg-blue-500 text-white px-4 py-2 rounded w-full"
            >
              URL einfügen
            </button>
          </div>

          <form onSubmit={handleSearch} className="mt-4 space-y-4">
            <div>
              <label htmlFor="keyword" className="block text-sm font-medium text-gray-700 mb-1">
                <h3>Keywords:</h3>
              </label>
              <input
                type="text"
                id="keyword"
                placeholder="Suchbegriffe"
                className="w-full p-2 border rounded text-sm"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>

            <div className="flex space-x-4">
              <div className="flex-1">
                <label htmlFor="radiusKm" className="block text-sm font-medium text-gray-700 mb-1">
                  Radius (km)
                </label>
                <input
                  type="number"
                  id="radiusKm"
                  className="w-full p-2 border rounded text-sm"
                  value={radiusKm}
                  onChange={(e) => setRadiusKm(Number(e.target.value))}
                />
              </div>
              <div className="flex-1">
                <label htmlFor="amount" className="block text-sm font-medium text-gray-700 mb-1">
                  Amount
                </label>
                <input
                  type="number"
                  id="amount"
                  className="w-full p-2 border rounded text-sm"
                  value={amount}
                  onChange={(e) => setAmount(Number(e.target.value))}
                />
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <button
                type="button"
                onClick={getCurrentLocation}
                className="bg-green-500 text-white px-4 py-2 rounded flex items-center"
              >
                <Crosshair className="mr-2" />
                Access your position
              </button>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="scrapeSocialMedia"
                  className="mr-2"
                  checked={scrapeSocialMedia}
                  onChange={(e) => setScrapeSocialMedia(e.target.checked)}
                />
                <label htmlFor="scrapeSocialMedia">Social Media scrapen</label>
              </div>
            </div>

            <button
              type="submit"
              className={`bg-blue-600 text-white px-6 py-3 rounded text-lg w-full ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={isLoading}
            >
              {isLoading ? 'Suche läuft...' : 'Searching'}
            </button>
          </form>

          {error && (
            <div className="mt-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
              <strong className="font-bold">Fehler: </strong>
              <span className="block sm:inline">{error}</span>
            </div>
          )}
        </div>
        
        <SearchResults results={results} />
      </div>
    </div>
  );
}

export default PlacesSearch;
