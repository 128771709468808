import React from 'react';
import { MapPin, Phone, Globe } from 'lucide-react';

function SearchResults({ results }) {
  if (results.length === 0) {
    return null;
  }

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mt-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Ergebnisse</h2>
        <p className="text-sm text-gray-600">Gefunden: {results.length} Ergebnisse</p>
      </div>
      <hr className="mb-4" />
      <div className="space-y-4">
        {results.map((place, index) => (
          <div key={index} className="flex items-center bg-gray-100 p-4 rounded-lg shadow">
            <div className="flex-grow">
              <h3 className="text-xl font-bold mb-2">{place.name}</h3>
              <div className="flex items-center mb-2">
                <MapPin className="mr-2" size={16} />
                <p className="text-sm">{place.vicinity}</p>
              </div>
              {place.city && place.country && (
                <div className="flex items-center mb-2">
                  <MapPin className="mr-2" size={16} />
                  <p className="text-sm">{place.city}, {place.country}</p>
                </div>
              )}
              {place.branch && (
                <div className="flex items-center mb-2">
                  <Globe className="mr-2" size={16} />
                  <p className="text-sm">Branch: {place.branch}</p>
                </div>
              )}
              {place.formatted_phone_number && (
                <div className="flex items-center mb-2">
                  <Phone className="mr-2" size={16} />
                  <p className="text-sm">{place.formatted_phone_number}</p>
                </div>
              )}
              {place.website && (
                <div className="flex items-center">
                  <Globe className="mr-2" size={16} />
                  <a
                    href={place.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline text-sm"
                  >
                    Website besuchen
                  </a>
                </div>
              )}
              <div className="mt-2 text-sm text-gray-600">
                <p>Lat: {place.lat?.toFixed(6)}, Lng: {place.lng?.toFixed(6)}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SearchResults;
