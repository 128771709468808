import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

// Beispielhafte Authentifizierungsprüfung
const useAuth = () => {
  // Hier sollte deine echte Authentifizierungslogik stehen
  const user = { isAuthenticated: false }; // Beispiel, ersetze durch echte Logik
  return user.isAuthenticated;
};

const PrivateRoute = ({ element }) => {
  const isAuthenticated = useAuth();
  const location = useLocation();

  return isAuthenticated ? (
    element
  ) : (
    <Navigate to="/signup" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
