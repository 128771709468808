import React, { useState, useEffect } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { supabase } from '../../auth/supabaseClient';

// Register the necessary components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [branchData, setBranchData] = useState({});
  const [countryData, setCountryData] = useState({});
  const [topBranches, setTopBranches] = useState({});

  useEffect(() => {
    fetchData();
    fetchTopBranches();
  }, []);

  const fetchData = async () => {
    const { data, error } = await supabase
      .from('places')
      .select('branch, country');

    if (error) {
      console.error('Error fetching data from Supabase:', error);
    } else {
      // Aggregate data by branch and country
      const branchCounts = {};
      const countryCounts = {};

      data.forEach((place) => {
        // Count occurrences by branch
        if (place.branch) {
          branchCounts[place.branch] = (branchCounts[place.branch] || 0) + 1;
        }

        // Count occurrences by country
        if (place.country) {
          countryCounts[place.country] = (countryCounts[place.country] || 0) + 1;
        }
      });

      setBranchData(branchCounts);
      setCountryData(countryCounts);
    }
  };

  const fetchTopBranches = async () => {
    const { data, error } = await supabase
      .from('places')
      .select('branch');

    if (error) {
      console.error('Error fetching top branches from Supabase:', error);
    } else {
      const branchCounts = {};

      data.forEach((place) => {
        if (place.branch) {
          branchCounts[place.branch] = (branchCounts[place.branch] || 0) + 1;
        }
      });

      setTopBranches(branchCounts);
    }
  };

  const branchChartData = {
    labels: Object.keys(branchData),
    datasets: [
      {
        label: 'Number of Places by Branch',
        data: Object.values(branchData),
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  const countryChartData = {
    labels: Object.keys(countryData),
    datasets: [
      {
        label: 'Number of Places by Country',
        data: Object.values(countryData),
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF',
        ],
      },
    ],
  };

  const topBranchesChartData = {
    labels: Object.keys(topBranches),
    datasets: [
      {
        label: 'Number of Places',
        data: Object.values(topBranches),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="container mx-auto px-6 py-8">
      <h1 className="text-3xl font-bold mb-6">Dashboard</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
        {/* Places by Branch */}
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Places by Branch</h2>
          <Bar data={branchChartData} />
        </div>

        {/* Places by Country */}
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Places by Country</h2>
          <Pie data={countryChartData} />
        </div>

        {/* Top Branches by Number of Places */}
        <div className="bg-white shadow-md rounded-lg p-6 col-span-2">
          <h2 className="text-xl font-semibold mb-4">Top Branches by Number of Places</h2>
          <Bar data={topBranchesChartData} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
