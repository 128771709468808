import React from 'react';
import PricingTier from './PricingTier';

class Pricing extends React.Component {
  render() {
    return (
      <section id="pricing" className="py-20 bg-gray-100 dark:bg-gray-800 transition-colors duration-300">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Transparente Preisgestaltung</h2>
          <div className="flex flex-wrap justify-center gap-8">
            <PricingTier 
              name="Starter" 
              price="29€" 
              features={['5 Nutzer', 'Basis-Support', '5GB Speicher']} 
            />
            <PricingTier 
              name="Pro" 
              price="99€" 
              features={['Unbegrenzte Nutzer', '24/7 Support', '100GB Speicher']} 
              recommended={true}
            />
            <PricingTier 
              name="Enterprise" 
              price="Individuell" 
              features={['Maßgeschneiderte Lösungen', 'Dedizierter Account Manager', 'Unbegrenzter Speicher']} 
            />
          </div>
        </div>
      </section>
    );
  }
}

export default Pricing;